@tailwind base;
@tailwind components;
@tailwind utilities;


.login-shadow{
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.loading-bg{
    background-color: rgb(0,0,0); /* Fallback color */
    background-color: rgba(0,0,0,0.08); /* Black w/ opacity */
}

.ag-row .ag-cell {
    display: flex;
    align-items: center;
  }
  

  .home-shadow{
    box-shadow: rgba(0, 0, 0, 0.45) 0px 25px 20px -20px;
  }
